/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Ajout Tailwindcss */
// settings : unknown SCSS + CSS > Lint: Unknown At Rules >> ignore 
// cf : https://flaviocopes.com/fix-unknown-at-rule-tailwind/
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,600,700,900');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

:root,
:root[mode="md"],
:root[mode="ios"] {
  --ion-font-family: 'Roboto';
  font-family: var(--ion-font-family) !important;
}

ion-modal.wideModal::part(content){
  --width: 100%;
  --height: 100%;
}

:root {
  // <colors background="0x333399" secondary="0xbc8dfc" tertiary="0xB1B3B3" text="0xffffff" confirm="0x228833" warning="0xC95858" corner="0x19BB7C"/>
  // <colors background="0x333399" secondary="0xbc8dfc" tertiary="0xB1B3B3" text="0xffffff" confirm="0x228833" warning="0xC95858" corner="0x19BB7C"/>

  --fasteleads-maxH: 197px;
  --fasteleads-colorBackground: #333399;
  --fasteleads-colorText: #ffffff;
  --fasteleads-colorConfirm: #228833;
  --fasteleads-colorSecondary:#bc8dfc;
  --fasteleads-colorTertiary: #B1B3B3;
  --fasteleads-colorWarning: #C95858;
  --fasteleads-colorCorner: #19BB7C;
  --fasteleads-colorBackgroundForm: #33339933;
  --fasteleads-fontFamily: "Roboto";

  // debug (jaune / rouge / vert)
  // --fasteleads-maxH: 197px;
  // --fasteleads-colorBackground: #ffc409;
  // --fasteleads-colorText: #eb445a;
  // --fasteleads-colorConfirm: rgb(21, 184, 0);
  // --fasteleads-colorSecondary: rgb(21, 184, 0);
  // --fasteleads-colorTertiary: rgb(21, 184, 0);
  // --fasteleads-colorWarning: rgb(21, 184, 0);
  // --fasteleads-colorBackgroundForm: rgba(21, 184, 0, 0.3);
  // --fasteleads-fontFamily: "Roboto";
}


// Hide all elements
// Capacitor - Barcode-Scanner (issue dark mode) - remove visibility: hidden
body.barcode-scanner-active {
  // visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}


body{
 &>div{
  z-index:-1;
 }
}
// retirer pour shepherd (2024/01/22)
//body {
//   &>div {
//     top: 50% !important;
//     left: 50% !important;
//     transform: translate(-50%, -50%) !important;
//     background-color: unset !important;
//     height: unset !important;
//   }
// }

* {
  font-family: var(--fasteleads-fontFamily),Roboto;
  font-weight: 400;
  // font-family: "Source Sans Pro";
}

.fl-custom-alert {
  .alert-wrapper {
    @apply bg-fl-warn text-center rounded-2xl text-fl-text w-1/2;
    --backdrop-opacity: 0.7;
    width: 80vw;
    max-width: 100vw;
  }

  .alert-title {
    @apply text-center text-fl-text font-bold text-lg;
  }

  .alert-message {
    @apply text-fl-text text-sm;

  }

  .alert-button-group {
    @apply px-2 mb-4 gap-3 justify-center;
  }

  .alert-button {
    @apply w-1/3 border-t-0 border-b-2 border-solid border-gray-700 text-center m-0 text-gray-900 bg-gray-100 font-bold rounded-2xl;
    min-width: unset;

    .alert-button-inner {
      @apply justify-center;
    }
  }
}

.fl-custom-alert-draft {
  .alert-wrapper {
    @apply bg-fl-warn text-center rounded-2xl text-fl-text w-1/2;
    --backdrop-opacity: 0.7;
    width: 80vw;
    max-width: 100vw;
  }
  .alert-title {
    @apply text-center text-fl-text font-bold text-lg;
  }
  .alert-sub-title {
    @apply text-fl-text text-xl;
  }
  .alert-message {
    @apply text-fl-text text-xl;
  }
  .alert-button-group {
    @apply px-2 mb-4 gap-3 justify-center;
  }
  .alert-button {
    @apply border-t-0 border-b-2 border-solid border-gray-700 text-center m-0 text-gray-900 bg-gray-100 font-bold rounded-2xl;
    min-width: unset;
    .alert-button-inner {
      @apply justify-center;
    }
  }
}


.ng2-pdf-viewer-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}



.CountryPicker {
  opacity: 0;
  --height: 100vh;
  --width: 100vw;
  --max-width: unset;
  --border-color: unset;

  &>.picker-wrapper {
    padding-top: 20px;

    .pickerLabel {
      text-align: center;
    }

    .pickerInput {
      background-color: rgb(234, 234, 234);
      border: 1px solid var(--fasteleads-colorTertiary);
      border-radius: 5px;
      padding: 5px;
      margin-inline: 10px;
      margin-block: 15px;
    }

    &>.picker-columns {
      &>ion-picker-column {
        &>div {
          button {
            margin-left: 35px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
          }
        }
      }
    }

    &>.picker-toolbar {
      &>.picker-toolbar-button {
        text-align: center;

        &>.picker-button {
          color: var(--fasteleads-colorText);
          background-color: var(--fasteleads-colorBackground);
          border-radius: 5px;
          height: unset;
          padding-block: 5px;
        }
      }
    }
  }
}

// .my-class.shepherd-text {
//   color: #ffff !important;
// }

// .shepherd-text
// {color:var(--fasteleads-colorText) !important;
//   font-size:1rem;line-height:1.3em;padding:.75em}

// .defaultShepherd {
//   background-color: var(--fasteleads-colorBackground) !important ;
//   border-radius: 10px !important;
//   shepherd-text {
//     color: var(--fasteleads-colorText) !important;

//   }
// }

ion-header::after {
  display: none;
}